import { StateCreator } from "zustand";
import { Speciality } from "../../../domain/models/doctor/speciality";
import { Qualification } from "../../../domain/models/doctor/qualification";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  DOCTOR_PROFILER_API_URL,
} from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteFetchDoctorMasters } from "../../../data/usecases/Doctors/remote-fetch-doctor-masters";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";

interface GetMastersDataInterface {
  qualifications: Qualification[];
  specialities: Speciality[];
  fetchMastersData: Function;
}

const initialStates = {
  qualifications: [],
  specialities: [],
};

export const useGetMastersDataSlice: StateCreator<GetMastersDataInterface> = (
  set,
  get
) => ({
  ...initialStates,

  fetchMastersData: async () => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    const axiosHttpClient = AxiosHttpClient.getInstance();
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: `Bearer ${token}`,
    });

    const remoteFetchDoctorMasters = new RemoteFetchDoctorMasters(
      `${DOCTOR_PROFILER_API_URL}${Endpoints.FETCH_DOCTOR_MASTERS}`,
      axiosHttpClient
    );

    let result = await remoteFetchDoctorMasters.fetch({});
    if (result.success) {
      set({
        qualifications: result.qualifications,
        specialities: result.specialities,
      });
    } else {
      Swal.fire("Error in fetching doctor masters", "", "error");
    }
  },
});
