import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { useDoctorStore } from "../../store/main/DoctorStore";
import { Masters } from "../../domain/models/doctor/doctorData";
import { Speciality } from "../../domain/models/doctor/speciality";
import Status from "../../domain/models/status";

type Props = {
  open: boolean;
  type: "qualifications" | "specialities";
  handleClose: Function;
  successCallback: Function;
  loading: boolean;
  masters: Masters[];
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type FormFields = {
  type: string;
  value: Speciality[];
};

const UpdateQualificationsAndSpecialitiesModal: React.FC<Props> = ({
  open,
  handleClose,
  successCallback,
  loading,
  type,
  masters,
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<FormFields>({
    mode: "onChange",
  });

  const { qualifications, specialities } = useDoctorStore();

  const handleSubmitMasters = (data: FormFields) => {
    const selectedMasters = data.value.map((master) => {
      return master.name;
    });
    successCallback(selectedMasters);
  };

  console.log(type);
  const updatedAndFilteredMastersArray = masters
    .filter((master) => master?.verification?.status !== Status.VERIFIED)
    .map(({ _id, value }) => ({
      _id: _id,
      name: value,
    }));

  const verifiedMasters = masters
    .filter((master) => master?.verification?.status == Status.VERIFIED)
    .map(({ _id, value }) => ({
      _id,
      name: value,
    }));

  useEffect(() => {
    setValue("type", type);
    setValue("value", updatedAndFilteredMastersArray);
  }, [open]);

  useEffect(() => {
    setValue("value", updatedAndFilteredMastersArray);
  }, [watch("type")]);

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack alignItems={"end"}>
          <CloseIcon color="error" onClick={() => handleClose()} />
        </Stack>
        <Typography fontWeight={500} variant="body1">
          Select Doctor {type}:
        </Typography>
        <Stack spacing={2} marginTop={2}>
          {watch("type") == "qualifications" && (
            <Controller
              name="value"
              control={control}
              render={({ field: { onChange, value = [] } }) => (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="size-small-outlined-multi"
                  size="small"
                  value={value}
                  onChange={(_, newValue) => {
                    const uniqueValues = newValue.filter(
                      (item, index, self) =>
                        index === self.findIndex((t) => t.name === item.name)
                    );
                    onChange(uniqueValues);
                  }}
                  options={qualifications.filter(
                    (qualification) =>
                      !verifiedMasters.some(
                        (master) => master.name === qualification.name
                      )
                  )}
                  getOptionLabel={(option) => option.name}
                  defaultValue={updatedAndFilteredMastersArray}
                  renderInput={(params) => (
                    <TextField {...params} label="Qualifications" />
                  )}
                />
              )}
              rules={{ required: { value: true, message: "Field Required" } }}
            />
          )}
          {watch("type") == "specialities" && (
            <Controller
              name="value"
              control={control}
              render={({ field: { onChange, value = [] } }) => (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="size-small-outlined-multi"
                  size="small"
                  value={value}
                  onChange={(_, newValue) => {
                    const uniqueValues = newValue.filter(
                      (item, index, self) =>
                        index === self.findIndex((t) => t.name === item.name)
                    );
                    onChange(uniqueValues);
                  }}
                  options={specialities.filter(
                    (speciality) =>
                      !verifiedMasters.some(
                        (master) => master.name === speciality.name
                      )
                  )}
                  getOptionLabel={(option) => option.name}
                  defaultValue={updatedAndFilteredMastersArray}
                  renderInput={(params) => (
                    <TextField {...params} label="Specialities" />
                  )}
                />
              )}
              rules={{ required: { value: true, message: "Field Required" } }}
            />
          )}

          <LoadingButton
            loading={loading}
            disabled={loading || !watch("value") || watch("value").length === 0}
            variant="contained"
            color="secondary"
            onClick={handleSubmit(handleSubmitMasters)}
          >
            <Typography fontWeight={550} color={"white"} textTransform={"none"}>
              Submit
            </Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UpdateQualificationsAndSpecialitiesModal;
