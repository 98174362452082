import {
  Box,
  Button,
  Collapse,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TherapyCompetitorDetails from "./TherapyCompetitorDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import { TherapyBrandDetails } from "../../../../domain/models/doctor/therapyBrandDetails";
import { TherapyCompetitor } from "../../../../domain/models/doctor/therapyCompetitor";

type Props = {
  handleCompetitorDetails: Function;
  handleBrandDetailsFunc: Function;
  brandDetails: TherapyBrandDetails[];
  handleTherapyDetails: Function;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.info.dark,
}));

type BrandDetails = {
  units: number;
  brandId: string;
  deptCode: string;
  name: string;
  orgCode: string;
  therapyId: string;
  therapyName: string;
  _id: string;
  competitors: TherapyCompetitor[];
  [prop: string]: string | number | TherapyCompetitor[];
};

const TherapyBrandDetailsComp: React.FC<Props> = ({
  handleCompetitorDetails,
  handleBrandDetailsFunc,
  brandDetails,
  handleTherapyDetails,
}) => {
  const [openExpand, setOpenExpand] = useState<boolean[]>([]);
  const [brands, setBrands] = useState<BrandDetails[]>([]);
  const [editBrand, setEditBrand] = useState<boolean[]>([]);

  useEffect(() => {
    brands.splice(0, brands.length);
    editBrand.splice(0, editBrand.length);
    for (let i = 0; i < brandDetails.length; i++) {
      openExpand.push(false);
      editBrand.push(false);

      brands.push({
        units: brandDetails[i].units,
        brandId: brandDetails[i].brand.brandId,
        deptCode: brandDetails[i].brand.deptCode,
        name: brandDetails[i].brand.name,
        orgCode: brandDetails[i].brand.orgCode,
        therapyId: brandDetails[i].brand.therapyId,
        therapyName: brandDetails[i].brand.therapyName,
        _id: brandDetails[i].brand._id,
        competitors: brandDetails[i].competitors,
      });
    }
    setEditBrand(editBrand);
    setBrands([...brands]);
  }, []);

  const handleChangeBrandDetails = (
    index: number,
    name: string,
    value: number
  ) => {
    editBrand[index] = true;
    const values = [...brands];
    values[index][name] = value;
    setBrands(values);
    handleBrandDetailsFunc(values[index].units, brands[index].brandId);
  };

  const handleEditBrand = (index: number, value: boolean) => {
    const values = [...editBrand];
    values[index] = value;
    setEditBrand(values);
  };

  const handOpenExpand = (isExpand: boolean, index: number) => {
    const values = [...openExpand];
    values[index] = isExpand;
    setOpenExpand([...values]);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <StyledTableRow sx={{ padding: 0 }}>
            <TableCell align="center">Brand</TableCell>
            <TableCell align="center">Units/Month</TableCell>
            <TableCell align="center">Action</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {brands.map((brand, index) => {
            handleTherapyDetails(brand.therapyName);
            return (
              <>
                <TableRow sx={{ backgroundColor: "info.dark" }}>
                  <TableCell align="center">{brand.name}</TableCell>
                  <TableCell>
                    <Stack
                      direction={"row"}
                      justifyContent={
                        brand.units && !editBrand[index] ? "right" : "center"
                      }
                      alignItems={"center"}
                    >
                      {brand.units >= 0 && !editBrand[index] ? (
                        brand.units
                      ) : (
                        <TextareaAutosize
                          name="units"
                          maxRows={1}
                          style={{
                            width: "30%",
                            resize: "none",
                          }}
                          minLength={1}
                          maxLength={3}
                          value={
                            Number.isNaN(brand.units)
                              ? ""
                              : brand.units.toString()
                          }
                          onChange={(e) => {
                            handleChangeBrandDetails(
                              index,
                              "units",
                              parseInt(e.target.value)
                            );
                          }}
                        />
                      )}
                      {!editBrand[index] && (
                        <Button
                          size="small"
                          onClick={() => handleEditBrand(index, true)}
                        >
                          <Typography
                            variant="caption"
                            color="blue"
                            textTransform={"none"}
                          >
                            Edit
                          </Typography>
                        </Button>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    {openExpand[index] ? (
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        onClick={() => handOpenExpand(false, index)}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        fontSize="small"
                        onClick={() => handOpenExpand(true, index)}
                      />
                    )}
                  </TableCell>
                </TableRow>
                {openExpand[index] && (
                  <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={12}>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <Box margin={0}>
                          <TherapyCompetitorDetails
                            handleCompetitorDetails={handleCompetitorDetails}
                            brandId={brand.brandId}
                            competitorDetails={brand.competitors}
                            competitorMaster={
                              brandDetails[index].competitorMaster
                            }
                          />
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TherapyBrandDetailsComp;
