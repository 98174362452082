import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import AddIcon from "@mui/icons-material/Add";
import AddAddressModal from "../AddAddressModal";
import { GetAddressByPincode } from "../../../domain/usages/get-address-by-pincode";
import { AddressFormFields } from "../../../domain/models/addressFormFields";
import { useState } from "react";
import { StoreDoctorAddress } from "../../../domain/usages/Doctors/store-address";
import Swal from "sweetalert2";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import AddContactsModal from "../AddContactsModal";
import { AddDoctorContacts } from "../../../domain/usages/Doctors/add-doctor-contacts";
import { ContactsFormFields } from "../../../domain/models/contactsFormFields";
import { Contacts, Verification } from "../../../domain/models/contacts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ConfirmDoctorAddress } from "../../../domain/usages/Doctors/confirm-doctor-address";
import { Address } from "../../../domain/models/doctor/address";
import { LoadingButton } from "@mui/lab";
import { UpdateDoctorAddress } from "../../../domain/usages/Doctors/update-doctor-address";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Status from "../../../domain/models/status";
import { Constants } from "../../../common/constants";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import UpdateQualificationsAndSpecialitiesModal from "../UpdateQualificationsAndSpecialitiesModal";
import { useDoctorStore } from "../../../store/main/DoctorStore";

type Props = {
  doctorDetails: DoctorData;
  getAddressByPincode: GetAddressByPincode;
  storeAddress: StoreDoctorAddress;
  getDetails: Function;
  addContacts: AddDoctorContacts;
  confirmAddress: ConfirmDoctorAddress;
  updateAddress: UpdateDoctorAddress;
};

interface DataItem {
  verification: Verification;
  name?: string;
  value?: string;
}

const DoctorDetails: React.FC<Props> = ({
  doctorDetails,
  getAddressByPincode,
  storeAddress,
  getDetails,
  addContacts,
  confirmAddress,
  updateAddress,
}) => {
  const theme = useTheme();
  const { updateDoctorQualifications, updateDoctorSpecialities } =
    useDoctorStore();

  const [openAddAddressModal, setOpenAddAddressModal] =
    useState<boolean>(false);
  const [openUpdateAddressModal, setOpenUpdateAddressModal] =
    useState<boolean>(false);
  const [openAddContactsModal, setOpenAddContactsModal] =
    useState<boolean>(false);
  const [
    openUpdateQualificationsAndSpecialitiesModal,
    setOpenUpdateQualificationsAndSpecialitiesModal,
  ] = useState<boolean>(false);
  const [typeOfUpdate, setTypeOfUpdate] = useState<
    "qualifications" | "specialities"
  >("qualifications");

  const [loading, setLoading] = useState(false);
  const [confirmAddressloading, setConfirmAddressLoading] =
    useState<boolean>(false);
  const geoLocation = useGeoLocation();

  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [selectedAddressToUpdate, setSelectedAddressToUpdate] =
    useState<string>("");

  const checkVerificationStatus = (name: string, data: DataItem[]): boolean => {
    const item = data.find(
      (entry) => entry.name === name || entry.value === name
    );
    return item ? item.verification?.status === Status.VERIFIED : false;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress(event.target.value);
  };

  const handleUpdateAddressModal = (value: boolean, id: string) => {
    setSelectedAddressToUpdate(id);

    setOpenUpdateAddressModal(value);
  };

  const storeDoctorAddress = async (data: AddressFormFields) => {
    let payload = {
      addresses: [
        {
          type: "primary",
          source: "profiling",
          latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
          line: data.line,
          landmark: data.landmark,
          pincode: parseInt(data.pincode),
          area: data.area,
          district: data.district,
          state: [data.state],
          country: [data.country],
        },
      ],
    };
    setLoading(true);
    let result = await storeAddress.store(doctorDetails._id, payload);
    if (result.success) {
      setLoading(false);
      handleCloseAddressModal();
      Swal.fire("Address stored successfully", "", "success");
      getDetails(doctorDetails._id);
    } else {
      setLoading(false);
      handleCloseAddressModal();
      Swal.fire("Failed to store address", "", "error");
    }
  };
  const updateDoctorAddress = async (data: AddressFormFields) => {
    let payload = {
      addresses: {
        type: "primary",
        source: "profiling",
        latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
        line: data.line,
        landmark: data.landmark,
        pincode: parseInt(data.pincode),
        area: data.area,
        district: data.district,
        state: [data.state],
        country: [data.country],
      },
    };
    setLoading(true);
    let result = await updateAddress.update(
      selectedAddressToUpdate,
      doctorDetails._id,
      payload
    );
    if (result.success) {
      setLoading(false);
      handleCloseAddressModal();
      Swal.fire("Address updated successfully", "", "success");
      getDetails(doctorDetails._id);
    } else {
      setLoading(false);
      handleCloseAddressModal();
      Swal.fire("Failed to update address", "", "error");
    }
  };

  const storeDoctorContacts = async (data: ContactsFormFields) => {
    let payload = {
      contacts: [
        {
          status: "active",
          type: data.type,
          value: data.value,
          source: "channelpay",
        },
      ],
    };
    setLoading(true);
    let result = await addContacts.add(doctorDetails._id, payload);
    if (result.success) {
      setLoading(false);
      handleCloseContactsModal();
      Swal.fire("Contacts stored successfully", "", "success");
      getDetails(doctorDetails._id);
    } else {
      setLoading(false);
      handleCloseContactsModal();

      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.errors.type) {
        Swal.fire(result.errors.type, "", "error");
      } else if (result.message) {
        Swal.fire(result.message, "", "error");
      } else {
        Swal.fire("Failed to store contacts", "", "error");
      }
    }
  };

  const storeDoctorQualifications = async (qualifications: string[]) => {
    setLoading(true);
    let result = await updateDoctorQualifications(
      doctorDetails._id,
      qualifications
    );

    if (result.success) {
      setLoading(false);
      handleCloseUpdateQualificationsAndSpecialitiesModal();
      Swal.fire("Qualifications updated successfully", "", "success");
      getDetails(doctorDetails._id);
    } else {
      setLoading(false);
      handleCloseUpdateQualificationsAndSpecialitiesModal();

      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.errors.type) {
        Swal.fire(result.errors.type, "", "error");
      } else if (result.message) {
        Swal.fire(result.message, "", "error");
      } else {
        Swal.fire("Failed to update qualifications", "", "error");
      }
    }
  };

  const storeDoctorSpecialities = async (specialities: string[]) => {
    setLoading(true);
    let result = await updateDoctorSpecialities(
      doctorDetails._id,
      specialities
    );

    if (result.success) {
      setLoading(false);
      handleCloseUpdateQualificationsAndSpecialitiesModal();
      Swal.fire("Specialities updated successfully", "", "success");
      getDetails(doctorDetails._id);
    } else {
      setLoading(false);
      handleCloseUpdateQualificationsAndSpecialitiesModal();

      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.errors.type) {
        Swal.fire(result.errors.type, "", "error");
      } else if (result.message) {
        Swal.fire(result.message, "", "error");
      } else {
        Swal.fire("Failed to update specialities", "", "error");
      }
    }
  };

  const storeDoctorMasters = async (masters: string[]) => {
    typeOfUpdate === "qualifications"
      ? storeDoctorQualifications(masters)
      : storeDoctorSpecialities(masters);
  };

  const landmarkRegrex = /^[a-zA-Z0-9]{15,}$/;
  const lineRegrex = /^[a-zA-Z0-9]{15,}$/;
  let regexPincode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  const handleEnableConfirmAddress = (address: Address) => {
    if (
      lineRegrex.test(address.line.replace(/[^a-zA-Z0-9]/g, "")) &&
      landmarkRegrex.test(address.landmark.replace(/[^a-zA-Z0-9]/g, "")) &&
      regexPincode.test(address.pincode)
    ) {
      return true;
    }
    return false;
  };

  const getDoctorAddressById = (id: string) => {
    const address = doctorDetails.addresses.filter((item) => item._id == id);
    return address;
  };
  const filterAddress = (address: Address) => {
    const formattedDoctorAddress = {
      line: address.line ? address.line : "",
      landmark: address.landmark ? address.landmark : "",
      area: address.area ? address.area : "",
      district: address.district ? address.district : "",
      pincode: address.pincode ? address.pincode : "",
      state: address.state[0],
      country: address.country[0],
    };
    const filteredAddress = Object.entries(formattedDoctorAddress).reduce(
      (acc: any, [key, value]) => {
        if (
          value !== "" &&
          value !== null &&
          (key === "line" ||
            key === "landmark" ||
            key === "district" ||
            key === "pincode" ||
            key === "area" ||
            key === "state" ||
            key === "country")
        ) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    return Object.values(filteredAddress).join(", ");
  };

  const handleOpenAddressModal = () => {
    setLoading(false);
    setOpenAddAddressModal(true);
  };
  const handleCloseAddressModal = () => {
    setOpenAddAddressModal(false);
  };
  const handleOpenContactsModal = () => {
    setLoading(false);
    setOpenAddContactsModal(true);
  };
  const handleCloseContactsModal = () => {
    setOpenAddContactsModal(false);
  };

  const handleOpenUpdateQualificationsAndSpecialitiesModal = () => {
    setLoading(false);
    setOpenUpdateQualificationsAndSpecialitiesModal(true);
  };
  const handleCloseUpdateQualificationsAndSpecialitiesModal = () => {
    setOpenUpdateQualificationsAndSpecialitiesModal(false);
  };

  const getLatestMobileNumber = (contacts: Contacts[]) => {
    const mobileContacts = contacts.filter(
      (contact) => contact.type === "mobile"
    );
    return mobileContacts.length > 0
      ? mobileContacts[mobileContacts.length - 1]
      : ({} as Contacts);
  };
  const getLatestEmailId = (contacts: Contacts[]) => {
    const mobileContacts = contacts.filter(
      (contact) => contact.type === "email"
    );
    return mobileContacts.length > 0
      ? mobileContacts[mobileContacts.length - 1]
      : ({} as Contacts);
  };
  const getLatestWhatsAppNumber = (contacts: Contacts[]) => {
    const mobileContacts = contacts.filter(
      (contact) => contact.type === "whatsapp"
    );
    return mobileContacts.length > 0
      ? mobileContacts[mobileContacts.length - 1]
      : ({} as Contacts);
  };

  const confirmDoctorAddress = async (addressId: string) => {
    setConfirmAddressLoading(true);

    let result = await confirmAddress.confirm(addressId, doctorDetails._id);
    if (result.success) {
      setConfirmAddressLoading(false);
      Swal.fire(
        "Thanks for confirming your doctor's address! We've saved it.",
        "",
        "success"
      );

      getDetails(doctorDetails._id);
    } else {
      setConfirmAddressLoading(false);
      if (result.errors) {
        let errorText = "";
        for (let x in result.errors) {
          errorText += result.errors[x] + "\n";
        }
        Swal.fire(errorText, "", "error");
      } else {
        Swal.fire(
          "Uh oh! There seems to be a problem saving your doctor's address.  Please try again.",
          "",
          "error"
        );
      }
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <GaTypography typographyType={TypographyType.LG_BOLD}>
          {doctorDetails.fullNames[doctorDetails.fullNames.length - 1].name
            ?.toLowerCase()
            .replace(Constants.CAPITALIZE_REGEX, (letter) =>
              letter.toUpperCase()
            )}
          {checkVerificationStatus(
            doctorDetails.fullNames[doctorDetails.fullNames.length - 1].name,
            doctorDetails.fullNames
          ) ? (
            <VerifiedIcon
              fontSize="small"
              color="success"
              style={{
                marginLeft: "5px",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <ErrorRoundedIcon
              fontSize="small"
              color="secondary"
              style={{
                marginLeft: "5px",
                verticalAlign: "middle",
              }}
            />
          )}
        </GaTypography>
      </div>
      {doctorDetails?.specialities?.length > 0 && (
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "flex-start",
          }}
        >
          {doctorDetails?.specialities?.some(
            (speciality) => speciality?.verification?.status === Status.VERIFIED
          ) ? (
            <VerifiedIcon
              fontSize="small"
              color="success"
              sx={{
                marginRight: "3px",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <ErrorRoundedIcon
              fontSize="small"
              color="secondary"
              sx={{
                marginRight: "3px",
                verticalAlign: "middle",
              }}
            />
          )}
          <GaTypography typographyType={TypographyType.SM}>
            Specialities :{" "}
            {doctorDetails?.specialities?.map((speciality, index) => (
              <span
                key={index}
                style={{
                  color:
                    speciality?.verification?.status === Status.VERIFIED
                      ? theme.palette.secondary.main
                      : "grey",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {speciality.value}
                {index < doctorDetails.specialities.length - 1 && ", "}
              </span>
            ))}
          </GaTypography>
          {doctorDetails.specialities.some(
            (speciality) => speciality?.verification?.status !== Status.VERIFIED
          ) && (
            <IconButton
              onClick={() => {
                setTypeOfUpdate("specialities");
                handleOpenUpdateQualificationsAndSpecialitiesModal();
              }}
              sx={{
                height: "20px",
              }}
            >
              <EditIcon
                fontSize="small"
                style={{
                  verticalAlign: "middle",
                }}
              />
            </IconButton>
          )}
        </Box>
      )}

      {doctorDetails?.qualification?.length > 0 && (
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "flex-start",
          }}
        >
          {doctorDetails?.qualification?.some(
            (qualification) =>
              qualification?.verification?.status === Status.VERIFIED
          ) ? (
            <VerifiedIcon
              fontSize="small"
              color="success"
              sx={{
                marginRight: "3px",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <ErrorRoundedIcon
              fontSize="small"
              color="secondary"
              sx={{
                marginRight: "3px",
                verticalAlign: "middle",
              }}
            />
          )}
          <GaTypography typographyType={TypographyType.SM}>
            Qualifications :{" "}
            {doctorDetails?.qualification?.map((qualification, index) => (
              <span
                key={index}
                style={{
                  color:
                    qualification?.verification?.status === Status.VERIFIED
                      ? theme.palette.secondary.main
                      : "grey",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {qualification.value}
                {index < doctorDetails.qualification.length - 1 && ", "}
              </span>
            ))}
          </GaTypography>
          {doctorDetails.qualification.some(
            (qualification) =>
              qualification?.verification?.status !== Status.VERIFIED
          ) && (
            <IconButton
              onClick={() => {
                setTypeOfUpdate("qualifications");
                handleOpenUpdateQualificationsAndSpecialitiesModal();
              }}
              sx={{
                height: "20px",
              }}
            >
              <EditIcon
                fontSize="small"
                style={{
                  verticalAlign: "middle",
                }}
              />
            </IconButton>
          )}
        </Box>
      )}
      {getLatestMobileNumber(doctorDetails.contacts).value && (
        <Stack direction={"row"} spacing={1}>
          <GaTypography typographyType={TypographyType.SM}>
            {getLatestMobileNumber(doctorDetails.contacts).verification
              ?.status === Status.VERIFIED ? (
              <VerifiedIcon
                fontSize="small"
                color="success"
                style={{
                  marginRight: 3,
                  verticalAlign: "middle",
                }}
              />
            ) : (
              <ErrorRoundedIcon
                fontSize="small"
                color="secondary"
                style={{
                  marginRight: 3,
                  verticalAlign: "middle",
                }}
              />
            )}
            Mobile :
          </GaTypography>
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >
            {getLatestMobileNumber(doctorDetails.contacts).value}
          </GaTypography>
        </Stack>
      )}
      {getLatestWhatsAppNumber(doctorDetails.contacts).value && (
        <Stack direction={"row"} spacing={1}>
          <GaTypography
            typographyType={TypographyType.SM}
            sx={{ flexShrink: "0" }}
          >
            {getLatestWhatsAppNumber(doctorDetails.contacts).verification
              ?.status === Status.VERIFIED ? (
              <VerifiedIcon
                fontSize="small"
                color="success"
                style={{
                  marginRight: 3,
                  verticalAlign: "middle",
                }}
              />
            ) : (
              <ErrorRoundedIcon
                fontSize="small"
                color="secondary"
                style={{
                  marginRight: 3,
                  verticalAlign: "middle",
                }}
              />
            )}
            Whatsapp :
          </GaTypography>
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >
            {getLatestWhatsAppNumber(doctorDetails.contacts).value}
          </GaTypography>
        </Stack>
      )}
      {getLatestEmailId(doctorDetails.contacts).value && (
        <Stack direction={"row"} spacing={1}>
          <GaTypography
            typographyType={TypographyType.SM}
            sx={{ flexShrink: "0" }}
          >
            {getLatestEmailId(doctorDetails.contacts).verification?.status ===
            Status.VERIFIED ? (
              <VerifiedIcon
                fontSize="small"
                color="success"
                style={{
                  marginRight: 3,
                  verticalAlign: "middle",
                }}
              />
            ) : (
              <ErrorRoundedIcon
                fontSize="small"
                color="secondary"
                style={{
                  marginRight: 3,
                  verticalAlign: "middle",
                }}
              />
            )}
            Email ID :
          </GaTypography>
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >
            {getLatestEmailId(doctorDetails.contacts).value}
          </GaTypography>
        </Stack>
      )}

      <Button
        sx={{ marginY: 2 }}
        size="small"
        color="secondary"
        startIcon={<AddIcon fontSize="small" />}
        onClick={() => handleOpenContactsModal()}
        variant="outlined"
      >
        <Typography variant="caption" fontSize={"10px"} fontWeight={660}>
          Add Contacts
        </Typography>
      </Button>

      {doctorDetails.addresses.length > 0 && (
        <>
          <Divider />
          <div style={{ marginTop: "14px" }}>
            <GaTypography typographyType={TypographyType.SM}>
              Address :
            </GaTypography>
            {doctorDetails.addresses.length == 0 && (
              <Typography color={"error"} variant="caption">
                We couldn't find your doctor's address in our system. Please add
                it for reference
              </Typography>
            )}
            <FormControl>
              <RadioGroup>
                <Stack gap={2}>
                  {doctorDetails.addresses.length > 0 &&
                    doctorDetails.addresses.map((address) => {
                      return (
                        <Card variant="outlined">
                          {!handleEnableConfirmAddress(address) && (
                            <Stack
                              alignItems={"center"}
                              right={0}
                              position={"absolute"}
                              direction={"column"}
                              justifyContent={"center"}
                            >
                              <IconButton
                                onClick={() =>
                                  handleUpdateAddressModal(true, address._id)
                                }
                              >
                                <EditLocationAltIcon
                                  fontSize="small"
                                  color="secondary"
                                />
                              </IconButton>
                            </Stack>
                          )}

                          <CardContent>
                            {address.isConfirmed ? (
                              <Stack
                                flexDirection={"row"}
                                gap={2}
                                alignItems={"center"}
                              >
                                <VerifiedIcon color="success" />
                                <Typography>
                                  {filterAddress(address)}
                                </Typography>
                              </Stack>
                            ) : (
                              <FormControlLabel
                                value={address._id}
                                control={
                                  <Radio
                                    size="small"
                                    disabled={
                                      !handleEnableConfirmAddress(address)
                                    }
                                    onChange={handleChange}
                                  />
                                }
                                label={filterAddress(address)}
                              />
                            )}
                          </CardContent>
                        </Card>
                      );
                    })}
                </Stack>
              </RadioGroup>
            </FormControl>
          </div>
        </>
      )}

      <div style={{ marginBottom: "6px", marginTop: "22px" }}>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyItems={"center"}
          gap={3}
          marginBottom={2}
        >
          <Button
            size="small"
            color="secondary"
            startIcon={<AddIcon fontSize="small" />}
            onClick={() => handleOpenAddressModal()}
            variant="outlined"
          >
            <Typography variant="caption" fontSize={"10px"} fontWeight={660}>
              Add Address
            </Typography>
          </Button>
          {doctorDetails.addresses.length > 0 && (
            <LoadingButton
              size="small"
              disabled={!selectedAddress}
              loading={confirmAddressloading}
              loadingPosition="start"
              color="secondary"
              startIcon={
                <CheckCircleIcon fontSize="small" sx={{ color: "white" }} />
              }
              onClick={() => confirmDoctorAddress(selectedAddress)}
              variant="contained"
            >
              <Typography
                color={"white"}
                variant="caption"
                fontSize={"10px"}
                fontWeight={660}
              >
                Confirm Address
              </Typography>
            </LoadingButton>
          )}
        </Stack>
      </div>
      <Divider />

      {openAddAddressModal && (
        <AddAddressModal
          open={openAddAddressModal}
          handleClose={handleCloseAddressModal}
          getAddressByPincode={getAddressByPincode}
          successCallback={storeDoctorAddress}
          loading={loading}
          addressData={{} as Address}
        />
      )}
      {openUpdateAddressModal && (
        <AddAddressModal
          open={openUpdateAddressModal}
          handleClose={() => handleUpdateAddressModal(false, "")}
          getAddressByPincode={getAddressByPincode}
          successCallback={updateDoctorAddress}
          loading={loading}
          addressData={
            getDoctorAddressById(selectedAddressToUpdate).length > 0
              ? getDoctorAddressById(selectedAddressToUpdate)[0]
              : ({} as Address)
          }
        />
      )}
      {openAddContactsModal && (
        <AddContactsModal
          open={openAddContactsModal}
          handleClose={handleCloseContactsModal}
          successCallback={storeDoctorContacts}
          loading={loading}
        />
      )}
      {openUpdateQualificationsAndSpecialitiesModal && (
        <UpdateQualificationsAndSpecialitiesModal
          open={openUpdateQualificationsAndSpecialitiesModal}
          handleClose={handleCloseUpdateQualificationsAndSpecialitiesModal}
          successCallback={storeDoctorMasters}
          type={typeOfUpdate}
          loading={loading}
          masters={
            typeOfUpdate === "qualifications"
              ? doctorDetails.qualification
              : doctorDetails.specialities
          }
        />
      )}
    </div>
  );
};

export default DoctorDetails;
