import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  DOCTOR_PROFILER_API_URL,
} from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { RemoteUpdateDoctorQualifications } from "../../../data/usecases/Doctors/remote-update-doctor-qualifications";

interface UpdateDoctorQualificationsInterface {
  updateDoctorQualifications: Function;
}

export const useUpdateDoctorQualificationsSlice: StateCreator<
  UpdateDoctorQualificationsInterface
> = (set, get) => ({
  updateDoctorQualifications: async (id: string, qualifications: string[]) => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    const axiosHttpClient = AxiosHttpClient.getInstance();
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: `Bearer ${token}`,
    });

    const remoteUpdateDoctorQualifications =
      new RemoteUpdateDoctorQualifications(
        `${DOCTOR_PROFILER_API_URL}${Endpoints.UPDATE_DOCTOR_QUALIFICATIONS}`,
        axiosHttpClient
      );

    const payload = {
      qualifications,
    };

    let result = await remoteUpdateDoctorQualifications.update(id, payload);

    return result;
  },
});
