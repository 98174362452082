import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { TherapyCompetitor } from "../../../../domain/models/doctor/therapyCompetitor";
import { BrandMaster } from "../../../../domain/models/doctor/brandMaster";

type Props = {
  handleCompetitorDetails: Function;
  brandId: number | string;
  competitorDetails: TherapyCompetitor[];
  competitorMaster: BrandMaster[];
};

type SelectedField = {
  name: boolean;
  units: boolean;
  [prop: string]: boolean;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
}));

type competitorDetailsType = {
  brandId: string;
  name: string;
  _id: string;
  units: number;
  [prop: string]: string | number;
};

const TherapyCompetitorDetails: React.FC<Props> = ({
  handleCompetitorDetails,
  brandId,
  competitorDetails,
  competitorMaster,
}) => {
  const [newCompetitorDetails, setNewCompetitorDetails] = useState<
    competitorDetailsType[]
  >([]);
  const [editCompetitor, setEditCompetitor] = useState<boolean[]>([]);
  const [showSelectedField, setShowSelectedField] = useState<SelectedField[]>(
    []
  );

  useEffect(() => {
    editCompetitor.splice(0, editCompetitor.length);
    showSelectedField.splice(0, showSelectedField.length);
    newCompetitorDetails.splice(0, newCompetitorDetails.length);
    const combinedCompetitors = competitorMaster.map((newCompetitor) => {
      const matchingCompetitor = competitorDetails.find(
        (existingCompetitor) => existingCompetitor.brandId === newCompetitor._id
      );
      return {
        ...newCompetitor,
        ...(matchingCompetitor && { units: matchingCompetitor.units }),
      };
    });

    for (let i = 0; i < combinedCompetitors.length; i++) {
      editCompetitor.push(false);
      showSelectedField.push({ name: false, units: false });
      newCompetitorDetails.push({
        brandId: combinedCompetitors[i]._id,
        name: combinedCompetitors[i].name,
        _id: combinedCompetitors[i]._id,
        units: combinedCompetitors[i].units ? combinedCompetitors[i].units : 0,
      });
    }
    setNewCompetitorDetails([...newCompetitorDetails]);
  }, []);

  const handleEditCompetitor = (index: number, value: boolean) => {
    const values = [...editCompetitor];
    values[index] = value;
    setEditCompetitor(values);
  };

  const handleChangeCompetitorUnits = (
    index: number,
    name: string,
    value: number,
    isSelect: boolean
  ) => {
    const values = [...newCompetitorDetails];
    const selectedValues = [...showSelectedField];
    selectedValues[index][name] = isSelect;

    values[index][name] = value;

    setNewCompetitorDetails(values);
    setShowSelectedField(selectedValues);
    handleCompetitorDetails(values, brandId);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <StyledTableRow sx={{ padding: 0 }}>
            <TableCell align="center">Competitor</TableCell>
            <TableCell align="center">Units/Month</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {newCompetitorDetails.map((competitor, index) => {
            return (
              <TableRow>
                <TableCell align="center">{competitor.name}</TableCell>
                <TableCell align="center">
                  {competitor.units >= 0 &&
                    !editCompetitor[index] &&
                    !showSelectedField[index].units && (
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography variant="caption">
                          {competitor.units}
                        </Typography>

                        <Button
                          size="small"
                          onClick={() => handleEditCompetitor(index, true)}
                        >
                          <Typography
                            variant="caption"
                            color="blue"
                            textTransform={"none"}
                          >
                            Edit
                          </Typography>
                        </Button>
                      </Stack>
                    )}

                  {(Number.isNaN(competitor.units) ||
                    editCompetitor[index] ||
                    showSelectedField[index].units) && (
                    <Stack
                      direction={"row"}
                      justifyContent={"space-around"}
                      alignItems={"center"}
                    >
                      <TextareaAutosize
                        name="units"
                        maxRows={1}
                        style={{ width: "50%", resize: "none" }}
                        minLength={1}
                        maxLength={3}
                        value={
                          Number.isNaN(competitor.units)
                            ? ""
                            : competitor.units && competitor.units.toString()
                        }
                        onChange={(e) => {
                          handleChangeCompetitorUnits(
                            index,
                            e.target.name,
                            parseInt(e.target.value),
                            true
                          );
                        }}
                      />
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TherapyCompetitorDetails;
