import { create } from "zustand";
import { useCommonSlice } from "../slice/doctor/commonSlice";
import { useGetMastersDataSlice } from "../slice/doctor/getMastersDataSlice";
import { useUpdateDoctorQualificationsSlice } from "../slice/doctor/updateDoctorQualificationsSlice";
import { useUpdateDoctorSpecialitiesSlice } from "../slice/doctor/updateDoctorSpecialities";

interface DoctorStoreInterface
  extends ReturnType<typeof useCommonSlice>,
    ReturnType<typeof useGetMastersDataSlice>,
    ReturnType<typeof useUpdateDoctorQualificationsSlice>,
    ReturnType<typeof useUpdateDoctorSpecialitiesSlice> {}

export const useDoctorStore = create<DoctorStoreInterface>((...data) => ({
  ...useCommonSlice(...data),
  ...useGetMastersDataSlice(...data),
  ...useUpdateDoctorQualificationsSlice(...data),
  ...useUpdateDoctorSpecialitiesSlice(...data),
}));
