import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  DOCTOR_PROFILER_API_URL,
} from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { RemoteUpdateDoctorSpecialities } from "../../../data/usecases/Doctors/remote-update-doctor-specialities";

interface UpdateDoctorSpecialitiesInterface {
  updateDoctorSpecialities: Function;
}

export const useUpdateDoctorSpecialitiesSlice: StateCreator<
  UpdateDoctorSpecialitiesInterface
> = (set, get) => ({
  updateDoctorSpecialities: async (id: string, specialities: string[]) => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    const axiosHttpClient = AxiosHttpClient.getInstance();
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: `Bearer ${token}`,
    });

    const remoteUpdateDoctorSpecialities = new RemoteUpdateDoctorSpecialities(
      `${DOCTOR_PROFILER_API_URL}${Endpoints.UPDATE_DOCTOR_SPECIALITIES}`,
      axiosHttpClient
    );

    const payload = {
      specialities,
    };

    let result = await remoteUpdateDoctorSpecialities.update(id, payload);

    return result;
  },
});
