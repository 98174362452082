import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchDoctors } from "../../../domain/usages/Doctors/fetch-doctors";
import SkeletonCard from "../../ga-components/SkeletonCard";
import DoctorCard from "./DoctorCard";
import DoctorSummary from "./DoctorSummary";
import InfiniteScroll from "react-infinite-scroll-component";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DoctorFilterModal from "./DoctorFilterModal";
import { FetchDoctorPrograms } from "../../../domain/usages/Doctors/fetch-doctor-programs";
import { Program } from "../../../domain/models/doctor/program";
import { useDoctorStore } from "../../../store/main/DoctorStore";

type Props = {
  fetchDoctors: FetchDoctors;
  fetchPrograms: FetchDoctorPrograms;
};

const DoctorsListSummary: React.FC<Props> = ({
  fetchDoctors,
  fetchPrograms,
}) => {
  const [doctorList, setDoctorList] = useState<DoctorData[]>([]);
  const [totalDoctors, setTotalDoctors] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [searchFilter, setSearchFilter] = useState("");
  const [programmElgible, setProgrammElgible] = useState<boolean>(false);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [openFilterModal, setOpentFilterModal] = useState<boolean>(false);

  const { doctorFilter, fetchMastersData } = useDoctorStore();

  const fetchProgramsForDoctor = async () => {
    let result = await fetchPrograms.fetch();
    if (result.success) {
      setPrograms(result.data);
    }
  };

  const handleFilterModal = (value: boolean) => {
    setOpentFilterModal(value);
  };

  const fetchDoctorsList = async (
    page: number,
    scrolled: boolean,
    programEligibility: boolean,
    docFilter?: any
  ) => {
    const queryFilter = docFilter || doctorFilter;

    const filter = {
      q: searchFilter,
      page: page,
      limit: 20,
      programCode: queryFilter.selectedProgram,
      programEligibility: programEligibility,
      isSampleReady: queryFilter.sampleEligible,
    };
    setProgrammElgible(programEligibility);
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchDoctors.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setLastPage(result.data.totalPages);
        setTotalDoctors(result.data.total);
        if (scrolled) {
          setDoctorList((oldData) => [...oldData, ...result.data.result]);
        } else {
          setDoctorList(result.data.result);
        }
        setFetchState(FetchState.SUCCESS);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };
  const cardStyle = {
    margin: "30px 20px 20px 20px",
    borderColor: "#94D2BD",
    width: "20rem",
  };
  useEffect(() => {
    fetchDoctorsList(1, false, programmElgible);
  }, [searchFilter]);

  useEffect(() => {
    fetchProgramsForDoctor();
    fetchMastersData();
  }, []);

  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };
  const debouncedOnChange = debounce(handleSearch, 2000);

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  return (
    <>
      <Stack margin={2} flexDirection={"row"} gap={1}>
        <TextField
          fullWidth
          size="small"
          onChange={debouncedOnChange}
          placeholder="Search Doctor..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <IconButton onClick={() => handleFilterModal(true)}>
          <FilterAltIcon fontSize="small" />
        </IconButton>
      </Stack>
      <div style={{ marginBottom: 2 }}>
        <DoctorSummary
          count={totalDoctors}
          fetchDoctorsList={fetchDoctorsList}
          fetchState={fetchState}
        />
      </div>

      <div
        id="div"
        style={{
          overflow: "auto",
          height: "60%",
          position: "fixed",
          width: "100%",
        }}
      >
        <InfiniteScroll
          scrollableTarget="div"
          dataLength={doctorList?.length}
          next={() => {
            fetchDoctorsList(currentPage + 1, true, programmElgible);
          }}
          hasMore={hasMoreData()}
          loader={<h4>Loading...</h4>}
        >
          {fetchState === FetchState.LOADING ? (
            <div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
            </div>
          ) : (
            <DoctorCard doctorCardDetails={doctorList} />
          )}
        </InfiniteScroll>
      </div>
      {openFilterModal && (
        <DoctorFilterModal
          open={openFilterModal}
          handleClose={() => handleFilterModal(false)}
          programs={programs}
          fetchDoctorsList={fetchDoctorsList}
        />
      )}
    </>
  );
};

export default DoctorsListSummary;
